<template>
  <div class="entry_box main">
    <div class="container content">
      <div class="title_box">
        <div class="title">News</div>
      </div>
      <div class="fud_box">
        <div class="enrty_list">
          <!-- <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="toDetail(item.id)"
          >
            <img class="img" :src="item.list_img" />

            <div class="tbox">
              <div class="time">{{ item.pub_date }}</div>
              <div class="name">{{ item.news_title }}</div>
            </div>
          </div> -->
          <div class="news_item" v-for="(item, index) in list" :key="index">
            <div class="img">
              <img v-if="item.titleimg != ''" :src="item.list_img" />
            </div>
            <div class="con">
              <div class="title">{{ item.news_title }}</div>
              <div class="text" v-html="item.news_abstract"></div>
              <div class="readmore" @click="toDetail(item.id)">READ MORE</div>
            </div>
          </div>
        </div>
        <ul class="news_nav_list">
          <li
            class="item"
            v-for="(item, index) in order_list"
            :key="index"
            @click="toDetail(item.id)"
          >
            <img class="img" :src="item.list_img" />
            <div class="info">
              <div class="time">{{ item.pub_date }}</div>
              <div class="name">{{ item.news_title }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_NEWS } from '@/api/index.js';

export default {
  name: 'Entry',
  components: {},
  data() {
    return {
      list: [],
      order_list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      GET_NEWS().then(res => {
        console.log(res);
        this.list = res.data;
        this.getorderList();
      });
    },
    getorderList() {
      this.order_list = [];
      if (this.list.length > 2) {
        for (let i = 0; i < 2; i++) {
          this.order_list.push(this.list[i]);
        }
      }
      console.log(this.order_list);
    },
    toDetail(id) {
      console.log(id);
      this.$router.push({
        path: '/NewsMedia/NewsDetail',
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title_box {
  .title {
    font-size: 24px;
  }
}
.enrty_list {
  width: 760px;
  flex: 0 0 760px;
  margin-top: 50px;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .news_item {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    .img {
      background-color: $bg;
      width: 50%;
      // min-height: 320px;
      overflow: hidden;
      position: relative;
      img {
        display: block;
        width: 100%;
        // height: 100%;
        // margin: 0 auto;
        // position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
      }
    }
    .con {
      width: 45%;
      // min-height: 320px;
      position: relative;
      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        margin-bottom: 15px;
        // 必须要大一点。特殊字体底部会被切割掉一点
        height: 63px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      .text {
        max-height: 190px;
        font-size: 16px;
        line-height: 24px;
        overflow: hidden;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      .readmore {
        background-color: #595757;
        text-align: center;
        font-size: 14px;
        line-height: 44px;
        width: 140px;
        color: #fff;
        position: absolute;
        bottom: 0;
        cursor: pointer;
      }
      .readmore2 {
        background-color: #595757;
        text-align: center;
        font-size: 16px;
        line-height: 44px;
        width: 140px;
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 150px;
        cursor: pointer;
      }
    }
  }
}
.news_nav_list {
  border-left: 1px solid #efefef;
  flex: 1;
  margin-left: 50px;
  margin-top: 50px;
  padding-left: 50px;
  .item {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 2px solid #efefef;
    cursor: pointer;
    display: flex;
    line-height: 24px;
    .img {
      display: block;
      width: 150px;
      margin-right: 10px;
    }
    .time {
      font-size: 12px;
    }
    .name {
      font-size: 12px;

      line-height: 18px;

      height: 36px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}
.fud_box {
  display: flex;
}
@media (max-width: 950px) {
  .fud_box {
    display: block;
  }
  .enrty_list {
    margin-top: 50px;
    display: flex;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .news_item {
      display: block;
      // justify-content: space-around;
      // align-content: center;
      width: 100%;
      .img {
        width: 100%;
        // min-height: 320px;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        img {
          display: block;
          width: 100%;
          // position: absolute;
          // left: 50%;
          // transform: translateX(-50%);
        }
      }
      .con {
        width: 100%;
        margin: 30px auto 0 auto;
        position: relative;
        .readmore {
          background-color: #595757;
          text-align: center;
          font-size: 16px;
          line-height: 44px;
          width: 140px;
          color: #fff;
          position: static;
          margin-top: 30px;
        }
      }
    }
  }
  .news_nav_list {
    display: none;
  }
}
</style>
